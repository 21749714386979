<template>
  <div class="short-video">
    <div class="header-box">
      <div class="header-title">
        <span class="title-main">数据表现</span>
        <span class="title-tip">{{ shortVideoData.statistics_time_text || "" }}</span>
      </div>

      <div class="header-operation">
        <!-- <el-radio-group class="radio-group" v-model="params.date_type" size="small" @change="handleChangeDateType">
          <el-radio-button :label="item.label" v-for="item in dateTypeOptions" :key="item.label">{{ item.text }}</el-radio-button>
        </el-radio-group> -->

        <el-date-picker
          v-model="filterParams.filterTime"
          :clearable="false"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          size="mini"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
          popper-class="date-no-light"
        >
        </el-date-picker>

        <!-- <el-button icon="el-icon-download" @click="exportData">导出数据</el-button> -->
      </div>
    </div>

    <div class="content-box">
      <div class="content-buttons">
        <div
          :class="['button-item', { 'is-active': currentVideoType == item.type }]"
          v-for="item in videoTypeOptions"
          :key="item.id"
          @click="handleChangeType(item.type)"
        >
          <div class="item-label">{{ item.label }}</div>
          <div class="item-data">{{ item.value }}</div>
        </div>
      </div>
      <div class="content-data">
        <!-- <div class="data-header">{{ getDataHeaderLabel }}</div> -->
        <div class="data">
          <div class="video-no-data" v-if="chartData.length == 0">
            <img src="../../assets/img/datacenter/business_no_data.svg" alt="" />
            <span>暂无数据</span>
          </div>

          <div class="video-chart" v-else>
            <div class="chart" ref="chart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ShortVideo",

  // 组件的数据
  data() {
    return {
      filterParams: {
        filterTime: [],
      },
      currentVideoType: "1",
      videoTypeOptions: [
        {
          id: "play",
          label: "播放量",
          type: "1",
          value: 0,
        },
        {
          id: "profile_uv",
          label: "主页访问",
          type: "2",
          value: 0,
        },
        {
          id: "like",
          label: "作品点赞",
          type: "3",
          value: 0,
        },
        {
          id: "share",
          label: "作品分享",
          type: "4",
          value: 0,
        },
        {
          id: "comment",
          label: "作品评论",
          type: "5",
          value: 0,
        },
        {
          id: "issue",
          label: "投稿量",
          type: "8",
          value: 0,
        },
        {
          id: "new_fans",
          label: "净增粉丝",
          type: "6",
          value: 0,
        },
        // {
        //   id: "unfollow_fans",
        //   label: "取关粉丝",
        //   type: "9",
        //   value: 0,
        // },
        {
          id: "total_fans",
          label: "总粉丝数",
          type: "7",
          value: 0,
        },
      ],
      dateTypeOptions: [
        {
          label: "1",
          text: "昨日",
          title: "昨日数据",
        },
        {
          label: "7",
          text: "近7天",
          title: "近7天趋势",
        },
        {
          label: "30",
          text: "近30天",
          title: "近30天趋势",
        },
      ],
      params: {
        id: "",
        // date_type: "1",
        data_type: "1",
        date: "",
      },
      shortVideoData: {},
      chartData: [],
      prevVideoData: {},
      // 日期不能选择今天以及今天以后的
      pickerOptions: {
        disabledDate(time) {
          const date = new Date();
          date.setDate(date.getDate() - 1);
          console.log(time.getTime() < date.getTime());
          return time.getTime() > date.getTime();
          // return time.getTime() > Date.now()
        },
      },
    };
  },

  props: {
    account: { type: Object, Required: true },
  },

  mounted() {
    this.initDefaultTime();
  },

  computed: {
    getDataHeaderLabel() {
      const dateStr = this.filterParams.filterTime.toString();
      return dateStr.replace(",", " 至 ") + "数据";
    },
    getChartTooltipLabel() {
      const videoTypeObj = this.videoTypeOptions.find((item) => item.type == this.currentVideoType);
      return videoTypeObj.label;
    },

    getExportLabel() {
      const dateType = this.dateTypeOptions.find((item) => item.label == this.params.date_type);
      const dataType = this.videoTypeOptions.find((item) => item.type == this.params.data_type);

      return `${dateType.text}${dataType.label}数据`;
    },
  },

  watch: {
    "account.id": {
      handler(newVal) {
        if (!newVal) return;

        this.params.id = newVal;
        this.initDefaultTime();
        // this.setParams(true);
        // this.getVideoData();
      },
      deep: true,
      immediate: true,
    },

    "filterParams.filterTime": {
      handler(newVal) {
        this.setParams(true);
        this.getVideoData();
      },
      deep: true,
      immediate: false,
    },
  },

  methods: {
    // 格式化日期为 yyyy-MM-dd
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },

    initDefaultTime() {
      const yesterday = new Date();
      yesterday.setDate(yesterday.getDate() - 1);
      const oneMonthAgo = new Date(yesterday);
      oneMonthAgo.setDate(yesterday.getDate() - 30);
      this.filterParams.filterTime = [this.formatDate(oneMonthAgo), this.formatDate(yesterday)];
    },

    // 格式化数量
    formatCount(value) {
      let count = parseFloat(value);
      if (isNaN(count)) return 0;

      let negativeFlag = count < 0;
      if (negativeFlag) count = -count;

      if (count < 10000) return `${negativeFlag ? "-" : ""}${count.toString()}`;
      else return `${negativeFlag ? "-" : ""}${(count / 10000).toFixed(2)}万`;
    },

    // 获取视频数据
    getVideoData() {
      if (!this.params.id) return;

      this.params.date = this.filterParams.filterTime.toString();

      this.$httpStudent.axiosGetBy(this.$api.dataCenterVideoSummary, this.params, (res) => {
        if (res.code == 200) {
          this.shortVideoData = res.data;

          this.videoTypeOptions.forEach((item) => {
            this.$set(item, "value", this.formatCount(this.shortVideoData[item.id]));
          });

          this.getChartData();
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 获取图表数据
    getChartData() {
      const paramsData = JSON.parse(JSON.stringify(this.filterParams.filterTime));

      if (paramsData.length > 0) {
        const pervDay = new Date(paramsData[0]);
        pervDay.setDate(pervDay.getDate() - 1);
        paramsData[0] = this.formatDate(pervDay);
      }

      this.params.date = paramsData.toString();

      this.$httpStudent.axiosGetBy(this.$api.dataCenterVideoChart, this.params, (res) => {
        if (res.code == 200) {
          this.prevVideoData = res.data?.shift();
          this.chartData = res.data;

          if (this.chartData.length > 0) this.$nextTick(() => this.setChartOption());
        } else {
          this.$message({
            type: "error",
            message: res.message,
            duration: 1000,
          });
        }
      });
    },

    // 设置图表
    setChartOption() {
      if (!this.$refs.chart) return;

      const data = this.chartData;

      data.forEach((item, index) => {
        const prevValue = index == 0 ? this.prevVideoData.value : data[index - 1].value;
        item.type = item.value < prevValue ? "down" : "up";
        if (prevValue == 0) {
          item.prencet = item.value == 0 ? "-" : `${item.value > 0 ? "+" : "-"}100%`;
        } else {
          const difference = ((item.value - prevValue) / Math.abs(prevValue)) * 100;
          item.prencet = difference == 0 ? "-" : `${difference > 0 ? "+" : ""}${difference.toFixed(2)}%`;
        }
      });

      const chart = this.$echarts.init(this.$refs.chart);
      const options = {
        grid: {
          left: "0%",
          right: "20",
          top: "10%",
          bottom: "5%",
          containLabel: true,
        },
        tooltip: {
          trigger: "axis", // 悬停时触发
          axisPointer: { type: "line" }, // 指示器类型为阴影
          formatter: (params) => {
            const currentData = data[params[0].dataIndex];
            return `
              <div style="width: 200px;">
                <div style="color: #51515C;">${params[0].name.length > 5 ? params[0].name.slice(-5) : params[0].name}</div>
                <div style="width: 100%;display: flex;justify-content: space-between;align-items: center;">
                    <div>
                      <i style="display: inline-block; width: 7px; height: 7px; border-radius: 50%; background-color: ${
                        params[0].color
                      };"></i> 
                      ${this.getChartTooltipLabel} 
                      <span style="color: ${currentData.type == "down" ? "#14c15a" : "#ff2c55"}; font-size: 12px;font-weight: bold;">${
              currentData.prencet
            }</span>
                    </div>
                    <div style="font-weight: bold; color: #292A37;">${params[0].value}</div>
                </div>
              </div>
            `;
          },
        },
        legend: {
          show: false,
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: data.map((item) => item.date),
          axisLabel: {
            color: "#86909C",
            formatter: function (value) {
              // 返回字符串的最后五个字符
              return value.length > 5 ? value.slice(-5) : value;
            },
          },
          axisLine: {
            show: true,
            lineStyle: { color: "#C9CDD4", type: "solid" },
            onZero: false,
            position: "bottom",
          },
          splitLine: { lineStyle: { type: "dashed" } },
          axisTick: { show: false },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            color: "86909C",
            formatter: "{value}",
          },
          axisLine: { show: false, lineStyle: { color: "#C9CDD4" } },
          splitLine: {
            show: true,
            lineStyle: {
              type: "dashed", // 最底部为实线，其他为虚线
              color: "#C9CDD4",
              formatter: function (value) {},
            },
          },
        },
        series: [
          {
            name: this.getChartTooltipLabel,
            type: "line",
            showSymbol: false,
            smooth: true,
            areaStyle: {
              origin: "start",
              // 设置渐变色
              color: {
                type: "linear",
                x: 0, // 渐变色的起始点 x 轴位置
                y: 0, // 渐变色的起始点 y 轴位置
                x2: 0, // 渐变色的结束点 x 轴位置
                y2: 1, // 渐变色的结束点 y 轴位置
                colorStops: [
                  { offset: 0, color: "rgba(223, 240, 254, 0.2)" }, // 起始颜色
                  { offset: 1, color: "rgba(0, 0, 255, 0)" }, // 结束颜色
                ],
                global: false, // 使用局部渐变色
              },
            },
            data: data.map((item) => item.value),
            itemStyle: {
              color: "#594fee",
            },
          },
        ],
      };
      // chart.clear();
      chart.setOption(options);

      window.addEventListener("resize", () => chart.resize());
    },

    // 数据类型切换
    handleChangeType(type) {
      this.currentVideoType = type;

      this.setParams();
      this.getChartData();
    },

    handleChangeDateType() {
      this.getChartData();
    },

    // params处理
    setParams(reset = false) {
      if (reset) {
        // this.params.date_type = "1";
        this.params.data_type = "1";
        this.currentVideoType = "1";
        // this.initDefaultTime();

        return;
      }

      this.params.data_type = this.currentVideoType;
    },

    // 导出数据
    exportData() {
      const exportParams = {
        id: this.params.id,
        // date_type: this.params.date_type,
        data_type: this.params.data_type,
        date: this.params.date,
        token: localStorage.getItem("studentToken"),
      };
      const label = this.getExportLabel;

      this.$httpStudent.axiosGetBy(this.$api.dataCenterVideoExport, exportParams, (res) => {
        const blob = new Blob([res], { type: ".xlsx" });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = `${label}.xlsx`; // 设置文件名
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.short-video {
  .header-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;

    .header-title {
      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 12px;
      color: rgba(37, 38, 50, 0.6);
      text-align: left;
      font-style: normal;

      .title-main {
        font-size: 18px;
        color: #111;
        margin-right: 10px;
        font-weight: 600;
      }
    }

    .header-operation {
      display: flex;
      align-items: center;
      .radio-group {
        padding: 4px;
        border-radius: 4px;
        background-color: #f2f2f4;
        margin-right: 10px;

        ::v-deep .el-radio-button__orig-radio + .el-radio-button__inner {
          background-color: transparent;
          color: rgba(37, 38, 50, 0.6);
          font-size: 14px;
          font-weight: 400;
          border-color: transparent;
          box-shadow: none;
          border-radius: 4px;
          padding: 2px 24px;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner {
          background-color: #ffffff;
          color: #594fee;
        }

        ::v-deep .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover {
          color: #594fee;
        }

        ::v-deep .el-radio-button__inner:hover {
          color: #594fee;
        }
      }

      ::v-deep .el-button {
        margin-left: 10px;
        padding: 6px 12px;
        background-color: #f2f2f4;
        border: none;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 14px;
        color: #777880;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: none;
      }

      ::v-deep .el-date-editor .el-range-input {
        background-color: transparent;
      }

      ::v-deep .el-date-editor--daterange.el-input,
      .el-date-editor--daterange.el-input__inner,
      .el-date-editor--timerange.el-input,
      .el-date-editor--timerange.el-input__inner {
        width: 250px;
      }

      ::v-deep .el-range-editor.el-input__inner {
        background-color: #f2f2f4;
        border: none;
      }
    }
  }

  .content-box {
    height: 405px;
    border: 1px solid #f2f2f4;
    box-sizing: border-box;

    .content-buttons {
      display: flex;
      align-items: center;

      .button-item {
        width: calc(100% / 8);
        height: 90px;
        background-color: #f9f9fa;
        box-sizing: border-box;
        border-bottom: 1px solid #f2f2f4;
        padding: 16px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        font-size: 14px;
        color: #252632;
        text-align: left;
        font-style: normal;
        cursor: pointer;

        &.is-active {
          .item-label {
            color: #594fee;
          }
          background-color: #fff;
          border-top: 2px solid #594fee;
          border-bottom: none;
        }

        .item-data {
          margin-top: 8px;
          font-size: 20px;
          font-weight: 700;
        }

        &:not(.button-item:last-of-type) {
          border-right: 1px solid #f2f2f4;
        }
      }
    }

    .content-data {
      height: calc(100% - 90px);
      box-sizing: border-box;
      // background-color: #111;
      padding: 16px;
      display: flex;
      flex-direction: column;

      .data-header {
        margin-bottom: 12px;
        font-size: 14px;
        font-weight: 600;
        font-family: PingFang SC, PingFang SC;
        color: #252632;
        flex-grow: 0;
      }

      .data {
        flex: 1;

        .video-no-data {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 32px;

          img {
            width: 100px;
            height: 100px;
            object-fit: cover;
          }

          span {
            font-size: 16px;
            font-weight: 600;
            color: #25263299;
            font-family: PingFang SC, PingFang SC;
          }
        }

        .video-chart {
          height: 100%;
          width: 100%;

          .chart {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
</style>
